import React, { useState } from 'react'

const RadioGroup = ({inputLabel, items, returnSelected, hasError}) => {

    const [selectedOption, setSelectedOption] = useState(null)

    const selectOption = (index, item) => {
        setSelectedOption(index)
        returnSelected(item)
    }

    return (
        <>
            <label className='block text-sm text-black'>{inputLabel}</label>
            {items.map((item, itemIndex)=>(
            <button key={itemIndex} className='w-full cursor-pointer flex items-center gap-x-4 my-3' onClick={()=>{selectOption(itemIndex, item)}}>
                <div 
                        className={`flex items-center justify-center rounded-full w-5 h-5 border-4 transition duration-200 text-white 
                        ${selectedOption === itemIndex ? 'bg-black' : 'bg-transparent border-black'}
                        ${hasError ? 'border-red-600' : 'border-black'}`
                    } 
                    
                >
                    {selectedOption === itemIndex && <span className='w-[10px] h-[10px] rounded-full bg-teal-300' />}
                </div>
                
                <label className={`${hasError ? 'text-red-600' : 'text-black'}`}>
                {item.label}
                </label>
            </button>
            ))
            }
        </>
    )
}

export default RadioGroup