import React from 'react'

const ThinArrow = ({className}) => {
  return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 100"
      className={className}
    >
        <path
        fill="none"
        stroke="currentColor"
        strokeWidth="1"
        d="M0 0L0 53"
        data-name="Line 24"
        transform="rotate(180 3.75 26.985)"
        ></path>
        <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        d="M1.538 6.877L7.501-.001l5.962 6.878"
        data-name="Path 45"
        ></path>
  </svg>
  )
}

export default ThinArrow