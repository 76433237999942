import './App.css';
import { Provider } from 'react-redux';
import store from './store/store';
import ErrorNotifier from './components/elements/ErrorNotifier';
import SuccessNotifier from './components/elements/SuccessNotifier';
import ScrollToTop from './components/layout/ScrollToTop';
import { Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import Store from './pages/store/Store';
import { useEffect } from 'react';
import Aos from 'aos';
import Contact from './pages/Contact';
import ShoppingBag from './pages/ShoppingBag';

function App() {
  
  useEffect(() => {
    Aos.init({
      disable: 'phone'
    });
  }, [])

  return (
    <Provider store={store}>
      <ErrorNotifier />
      <SuccessNotifier />
      <ScrollToTop>
        <Routes>
          <Route path="/" exact element={<LandingPage />} />
          <Route path="store" exact element={<Store />} />
          <Route path="shopping-bag" exact element={<ShoppingBag />} />
          <Route path="contact-us" exact element={<Contact />} />
        </Routes>
      </ScrollToTop>
    </Provider>
  );
}

export default App;
