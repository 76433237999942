import React from 'react'

const NavToggle = ({
    menuActive,
    openMenu,
    closeMenu
}) => {
  return (
    <div className='cursor-pointer w-full h-full flex items-center justify-center' onClick={()=>{menuActive ? closeMenu() : openMenu()}}>
        <div>
            <div className={`transition duration-300 ${menuActive ? 'rotate-45 translate-y-[4px] bg-black' : 'bg-stone-500'} w-[35px] my-2 h-[2px]`} />
            <div className={`transition duration-300 ${menuActive ? 'hidden' : ''} w-[35px] my-2 h-[2px] bg-stone-500`} />
            <div className={`transition duration-300 ${menuActive ? '-rotate-45 -translate-y-[4px] bg-black -mt-[2px]' : 'bg-stone-500'} w-[35px] my-2 h-[2px]`} />
        </div>
    </div>
  )
}

export default NavToggle