import React from 'react'
import Rating from './Rating'
import BagIcon from './icons/BagIcon'
import InlinePreloader from './InlinePreloader'
import { useDispatch, useSelector } from 'react-redux'
import { clientId } from '../../utils'
import { deductFromCart, sendToCart } from '../../store/actions/cartActions'
import PlusIcon from './icons/PlusIcon'
import { MinusIcon } from '@heroicons/react/20/solid'
import PlaceholderImage from '../../assets/img/icons/donut-illustration.png'

const StoreItem = ({item}) => {
    const dispatch = useDispatch()
    const cartState = useSelector((state => state.cart))

    const addItemToCart = async () => {
        const newCartItem = {
            displayName: item.displayName,
            item: item.item._id,
            parentItem: item.parentItem._id,
            parentItemCategory: item.parentItemCategory._id,
            quantity: 1,
            price: item.price,
        }

        const requestPayload = {
            clientId: clientId(),
            item: newCartItem
        }
    
        dispatch(sendToCart(requestPayload))

    }

    const deductItemFromCart = async () => {
        const requestPayload = {
            clientId: clientId(),
            item: item.item._id,
            quantity: 1
        }
    
       dispatch(deductFromCart(requestPayload))
    }

    const itemInCart = () => {
        const indexOfItemInCart = cartState?.cart?.items?.findIndex((cartItem) => 
            cartItem?.item === item.item._id
        )
        return indexOfItemInCart
    }
    

    return (
        <div className="w-full" data-aos="fade-up" data-aos-anchor-placement="top-center">
            <div className='relative h-[300px] bg-gray-100 flex items-center justify-center' style={{
                backgroundImage: `url(${item?.parentItem?.coverImage})`, 
                backgroundPosition: 'center center', 
                backgroundSize: 'cover'
            }}>
                {!item?.parentItem?.coverImage && <img src={PlaceholderImage} className='w-[30%] opacity-20' alt="placeholder" />}
            </div>
            <div className='w-full flex items-center justify-between my-4'>
                <p className='font-[600] text-xl font-red-hat'>₦{item.price.toLocaleString()}</p>
                {(!itemInCart() || itemInCart() < 0) && <>
                    {!cartState.sendingToCart ? <button onClick={()=>{addItemToCart()}} className='rounded-md bg-nadabake-purple text-white px-3 py-2 flex items-center justify-center gap-x-2 cursor-pointer hover:bg-black transition duration-200 text-sm'>
                        Add to bag <BagIcon className={`w-[20px] h-[20px]`} />
                    </button> :
                        <InlinePreloader />
                    }
                </> }
                {itemInCart() > -1 && <div className='flex flex-row-reverse'>
                    <button onClick={()=>addItemToCart()} className='flex items-center gap-x-2 text-xs px-2'>
                        <PlusIcon className='w-5 h-5 text-gray-500' />
                    </button>
                    <p className='font-thin text-lg'>{cartState.cart.items[itemInCart()].quantity}</p>
                    <button  onClick={()=>deductItemFromCart()} className='flex items-center gap-x-2 text-xs px-2'>
                        <MinusIcon className='w-5 h-5 text-gray-500' />
                    </button>
                </div> 
                }
            </div>
            <Rating rating={5} />
            <p className='mt-3 font-red-hat font-medium'>{item.parentItem?.name} - {item.item?.name}</p>        
            {/* {item.categories && item.categories.length > 0 &&  */}
                <div className='mt-1'>
                    <p className='text-sm'>Categories</p>
                    <div className='flex items-center gap-x-2 flex-wrap gap-y-2 mt-1'>
                        {/* {[item?.parentItemCategory?.name].map((cat, catIndex)=>(<span key={catIndex} className="px-2 py-1 rounded-md bg-gray-100 text-gray-500 text-sm font-syne">{cat}</span>))} */}
                        <span className="px-2 py-1 rounded-md bg-gray-100 text-gray-500 text-sm font-syne">{item?.parentItemCategory?.name}</span>
                    </div>
                </div>
            {/* } */}

        </div>
    )
}

export default StoreItem