import React from 'react'
import NadabakeLogo from '../../assets/img/logo/logo-white.svg'
// import Image from 'next/image'
import Facebook from '../../assets/img/icons/facebook.svg'
import Instagram from '../../assets/img/icons/instagram.svg'

const Footer = () => {
  return (
    <footer className='min-h-[50vh] h-inherit bg-black py-8 px-8 lg:px-24 xl:px-32 2xl:px-44'>
      <div className='w-full lg:flex items-start gap-x-8'>
        <div className='w-full xl:w-4/12'>
          <div className='mb-8 flex items-center gap-x-6'>
            <div className='relative inline-block w-6'>
              <img 
                  alt=''
                  src={NadabakeLogo}
              />

            </div>
            <p className='text-white tracking-[0.2em]'>NADABAKEHOUSE</p>
          </div>
          <div className='w-full xl:w-2/3'>
            <p className='text-sm text-gray-400'>We believe in the magic of simple ingredients, transformed by skilled hands and a sprinkle of love. Our bakers are artists, weaving flour, butter, and sugar into edible stories of joy. Each loaf, cake, and cookie tells a tale of tradition and innovation, a testament to our commitment to quality and flavor.</p>
          </div>
        </div>

        <div className='hidden xl:block w-4/12'
        ></div>
        <div className='w-full lg:w-4/12 mt-12 lg:mt-0'>
          <div className='block w-full'>
            <div className='w-full lg:text-right mb-12'>
              <p className='font-medium text-white'>LET&apos;S TALK</p>
              <p className='font-light text-stone-300 mb-6'>+234 817 244 4555</p>
              <p className='font-medium text-stone-300'>GET IN TOUCH</p>
              <p className='font-light text-stone-300'>nadabakehouse@gmail.com</p>
            </div>
            <div className='w-full h-20 flex'>
              <div className='w-full flex lg:flex-row-reverse items-center gap-x-6'>
                <a href='https://www.facebook.com/profile.php?id=61551824843010&mibextid=LQQJ4d' target="_blank" rel='noreferrer' className='relative inline-block w-6 text-center'>
                  <img 
                    alt=''
                    src={Facebook}
                    className='filter invert'
                  />
                </a>
                <a href='https://instagram.com/nadabakehouse' target='_blank' rel='noreferrer' className='relative inline-block w-6'>
                  <img 
                    alt=''
                    src={Instagram}
                    width={24}
                    className='filter invert'
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer