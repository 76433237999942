'use client';
import { Transition } from "@headlessui/react";
import ChevronIcon from "../elements/icons/ChevronIcon";
import ThinArrow from "../elements/icons/ThinArrow";
import { useState } from "react";
import Slide1 from '../../assets/img/slider/1.jpeg'
import Slide2 from '../../assets/img/slider/2.jpeg'
import Slide3 from '../../assets/img/slider/3.jpeg'
import Slide4 from '../../assets/img/slider/4.jpeg'

const Hero = () => {
    const scrollDown = () => {
        window.scrollTo({
            top: 1000,
            behavior: "smooth",
        });
      };
    
      const [activeSlideIndex, setActiveSlideIndex] = useState(0);
      const [slideOn, setSlideOn] = useState(true)
    
      const slides = [
        {
          image: Slide3
        },
        {
          image: Slide2
        },
        {
          image: Slide1
        },
        {
          image: Slide4
        },
      ]
    
      const nextSlide = () => {
          setSlideOn(false)
    
          if (activeSlideIndex === slides.length - 1) {
            setActiveSlideIndex(0)
              setTimeout(() => {
                  setSlideOn(true)
              }, 300);
              return
          }
    
          setActiveSlideIndex(activeSlideIndex + 1)
          setTimeout(() => {
              setSlideOn(true)
          }, 300);
    
      }
      
      const previousSlide = () => {
          setSlideOn(false)
    
          if (activeSlideIndex === 0) {
            setActiveSlideIndex(slides.length - 1)
            setTimeout(() => {
                setSlideOn(true)
            }, 300);
            return
          }
      
          setActiveSlideIndex(activeSlideIndex - 1)
    
          setTimeout(() => {
              setSlideOn(true)
          }, 300);
      }

  return (
        <section className="relative pt-[100px] 2xl:pt-0">
            <div className="-rotate-90 absolute -left-[300px] top-[400px]">
            <h1 className='font-[600] text-nadabake-purple text-opacity-10 text-[100px] hidden xl:block'>nadabakehouse</h1>
            </div>
            <div className="min-h-screen h-inherit xl:flex items-center justify-between px-8 lg:px-24 xl:px-32 2xl:px-44 w-full">
                <div className="w-[55%] h-[90vh] bg-nadabake-purple bg-opacity-10 absolute top-0 right-0 z-0"></div>
                <div className="w-full mt-12 xl:mt-0">
                    <h1 className="text-4xl xl:text-6xl font-bold leading-[1.2em]">From our oven <br />to your doorstep</h1>
                    <p className="uppercase tracking-[0.2em] mt-5 mb-5 text-nadabake-purple">A Slice of Bakery Bliss Awaits</p>

                    <p className="">But the true magic happens when our creations find their way to you. Let the buttery richness of our croissants melt on your tongue. Savor the delicate sweetness of our fruit tarts. Share the warmth of a freshly baked loaf with loved ones. With every bite, discover the difference that comes from from our oven to your doorstep.</p>

                    <div className='flex justify-between mt-12 w-full'>
                        <div className='flex items-center gap-x-4'>
                            <div className='h-px w-8 bg-black' />
                            <p className='text-black'>0{activeSlideIndex+ 1}/0{slides.length}</p>
                        </div>
                        <div className='flex items-center w-32 relative'>
                            <div className='w-full absolute bottom-0 h-1 left-0 bg-gray-300'>
                                <div className='w-1/3 bg-nadabake-purple h-full' />
                            </div>
                            <button className='bg-gray-200 w-full h-[70px] flex items-center justify-center border-r border-gray-300 transition duration-200 hover:bg-gray-300' onClick={()=>{previousSlide()}}>
                                <ChevronIcon className='text-gray-600 w-4 h-4' />
                            </button>
                            <button className='bg-white w-full h-[70px] flex items-center justify-center transition duration-200 hover:bg-gray-300' onClick={()=>{nextSlide()}}>
                                <ChevronIcon className='rotate-180 text-gray-600 w-4 h-4' />
                            </button>
                        </div>
                    </div>
                </div>

                <div className='w-full lg:pl-16 relative mt-8 xl:mt-0'>
                    <Transition
                        appear={true}
                        show={slideOn}
                        enter="transition ease-in-out duration-150 transform"
                        enterFrom="opacity-0 scale-125"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in-out duration-150 transform"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 -scale-125"
                    >
                        <div className='w-full relative'>
                            <img 
                                src={slides[activeSlideIndex].image}
                                layout="responsive" 
                                className=""
                                alt='' 
                            />
                        </div>
                    </Transition>
                </div>

                <div className='hidden lg:block absolute right-8 bottom-24'>
                    <div className='animate__animated animate__fadeInUp'>
                    {/* <Link href={`#brands`}> */}
                        <button className='transform rotate-90 flex items-center flex-row gap-x-4 hover:translate-y-2 transition duration-200 pt-6' onClick={()=>{scrollDown()}}>
                        <p className='font-medium text-black text-sm'>SCROLL DOWN</p>
                        <ThinArrow className="w-4 text-black rotate-90" />
                        </button>
                    {/* </Link> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero