import React from 'react'
import InlinePreloader from '../InlinePreloader'

const FormButton = ({buttonLabel, buttonAction, processing}) => {
  return (
    <button onClick={()=>{buttonAction()}} className='rounded w-full px-4 py-3 bg-black text-white text-md transition duration-200 hover:bg-gray-800 flex items-center justify-center'>
      {processing ? <InlinePreloader /> : buttonLabel}
    </button>
  )
}

export default FormButton