import { nanoid } from "nanoid";

// export const baseUrl = 'http://localhost:5550'
export const baseUrl = process.env.REACT_APP_API_URL
export const storeId = process.env.REACT_APP_STORE_ID

export const convertCamelCase = (camelCaseText) => {
    const text = camelCaseText;
    const result = text.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

    return finalResult
}

export const tableHeadersFields = (sampleObject) => {
    if(!sampleObject) {
        return []
    }
    const headers = []
    const fields = []
    Object.keys(sampleObject).forEach((key, index)=>{
        let columnDataType = 'text'
        let forPopover = false
        let columnDisplayName = convertCamelCase(key)
        let sortable = false
        let column = key

        headers.push({
            column,
            columnDisplayName,
            data: sampleObject[key],
            sortable,
            forPopover,
            columnDataType
        })

        let fieldSelected = true

        if(index > 10) {
            fieldSelected = false
        }
        fields.push({
            name: columnDisplayName,
            selected: fieldSelected
        })
    });
    return {headers, fields}
}

export const isValidObject = (obj) => {
    return obj && obj !== 'null' && obj !== 'undefined';
}

export const slugify = (string) => {
    if (!string || string === '' ) {
        return ""
    }
    const updated = string.toLowerCase()
    const slugified = updated.split(' ').join('-')

    return slugified
}

export const unSlugify = (string) => {
    if(!string || string === '') {
        return
    }
    return string.replace(/[_-]/g, " "); 
    // return string.replace(/[^0-9_-]/g, ' ')
}

export const formatPhone = (phone) => {
    let formatted =""
    if (!phone || phone === '') {
        return ""
    }

    if (phone.charAt(0) === '0') {
        formatted = '+234' + phone.substring(1)
    } else {
        formatted = phone
    }

    return formatted
}

export const clientId = () => {
    const client = JSON.parse(localStorage.getItem('clientId'));
    if (!client || client === '') {
        const newClient = nanoid(25)
        localStorage.setItem("clientId", JSON.stringify(newClient));
        return newClient
    } else {
      return client;
    }
}

export const authHeader = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.authToken) {
      return { Authorization: 'Bearer ' + user.authToken };
    } else {
      return {};
    }
}

export const userPermissions = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    // const userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    const userPermissions = user.permissions
    return userPermissions
}

export const businessDetails = () => {
    const business = JSON.parse(localStorage.getItem('business'));
    return business
}

// export const storeDetails = async () => {
//     // const business = JSON.parse(localStorage.getItem('business'));
//     const result = await db.allDocs({ include_docs: true });
//     if(!result) {
//         return null
//     }
//     const store = result.rows.find(item => item.doc.document === 'business');
//     if(!store) {
//         return null
//     }
//     return store.doc
// }

export const userDetails = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return user
}

export const parseFilters = (filtersArray) => {
    if(!filtersArray || filtersArray.length === 0) {
        return ''
    }

    let filtersString = filtersArray.map((filterObject) => {
        let string = 'filter='
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(filterObject)) {
            string += `${value}||`
          }
        return string.slice(0, -2)
    })

    return filtersString.join('&').toString()
} 

export const toTimeStamp = (date) => {
    const dateObj = new Date(date)
    const month = dateObj.getUTCMonth() + 1
    const day = dateObj.getUTCDate()
    const year = dateObj.getUTCFullYear()
    const time = dateObj.getUTCHours() + ':' + dateObj.getUTCMinutes() + ':' + dateObj.getUTCSeconds()
    const timeStamp = `${month}/${day}/${year} @ ${time}`
    return timeStamp
}

export const sortArrayByObjectKey = (array, key, direction) => {
    if(!array || !key) {
        return
    }

    function SortArray(x, y){
        if(direction === 'ASC') {
            return x[key].localeCompare(y[key]);
        } else {
            return y[key].localeCompare(x[key]);
        }
    }

    var s = array.sort(SortArray);

    return s
}

const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0');
} 
  
export const formatDate = (date) => {
return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
].join('-');
}

// const countryStates = require('./assets/static/country-states.json')

// export const parseNigerianStates = () => {
//     const statesArray = []

//     for (const [key, value] of Object.entries(countryStates.NG.divisions)) {
//         statesArray.push({
//             label: value,
//             value: key
//         })
//     }

//     return statesArray
// }

export const generateCode = (length, isNumeric) => {
    if (!isNumeric) { isNumeric = false }
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    if (isNumeric) {
        characters = '0123456789';
    }
    var charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const searchArray = (array, searchQuery) => {
    // Convert the searchQuery to lowercase for case-insensitive search
    const query = searchQuery.toLowerCase().trim();

    // Filter the array based on the search query
    return array.filter((item) => {
        // Check each field in the object for likeness
        for (const key in item) {
            if (item?.hasOwnProperty(key)) {
                const value = item[key]?.toString()?.toLowerCase();
                if (value?.includes(query)) {
                    return true; // Found a match in this object
                }
            }
        }
        return false; // No match found in this object
    });
}

export const paginateArray = (array, page, pageSize) => {
    // Calculate the starting and ending indexes for the current page
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    // Return a slice of the array for the current page
    return array.slice(startIndex, endIndex);
}

export const debounce = (func, timeout = 1000) => {
    let timer;
    return function (...args) {
        clearTimeout(timer);
        timer = setTimeout(() => func.apply(this, args), timeout);
    };
}

// Helper function to check if a date is within the current month
export const isDateInCurrentMonth = (date) => {
    const currentDate = new Date();
    return date.getMonth() === currentDate.getMonth() && date.getFullYear() === currentDate.getFullYear();
}
  
  // Helper function to check if a date is within the current week
export const isDateInCurrentWeek = (date) => {
    const currentDate = new Date();
    const oneWeekAgo = new Date(currentDate);
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    return date >= oneWeekAgo && date <= currentDate;
}

export const listLowStockVariants = (inventory) => {
    const lowStockVariants = [];

    inventory.forEach((item) => {
        if(item.type === 'sale') {
            item.variants.forEach((variant) => {
                if (variant.currentStock < variant.lowStockAlertCount) {
                    const lowStockInfo = {
                        id: item._id,
                        itemName: item.name,
                        variantName: variant.name,
                        currentStock: variant.currentStock,
                        saleUnit: variant.saleUnit,
                    };
                    lowStockVariants.push(lowStockInfo);
                }
            });
        }
        if(item.type === 'store') {
            if (item.currentStock < item.lowStockAlertCount) {
                const lowStockInfo = {
                    id: item._id,
                    itemName: item.name,
                    variantName: '',
                    currentStock: item.currentStock,
                    saleUnit: item.stockUnit,
                };
                lowStockVariants.push(lowStockInfo);
            }
        }
    });

    return lowStockVariants;
}

export const getTransactionSummary = (transactions) => {
    const today = new Date();
    const past10Days = [];
    const past10Weeks = [];
    const past10Months = [];
    const dayTransactions = {};
    const weekTransactions = {};
    const monthTransactions = {};
  
    for (let i = 0; i < 10; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      past10Days.push(date);
    }
  
    for (let i = 0; i < 10; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - 7 * i);
      past10Weeks.push(date);
    }
  
    for (let i = 0; i < 10; i++) {
      const date = new Date(today);
      date.setMonth(today.getMonth() - i);
      past10Months.push(date);
    }
  
    past10Days.forEach((date) => {
      const dayKey = date.toLocaleString('en-US', { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' });
      dayTransactions[dayKey] = { day: dayKey, amount: 0, transactionsByChannel: {} };
    });
  
    past10Weeks.forEach((date) => {
      const weekKey = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      weekTransactions[weekKey] = {
        week: weekKey,
        amount: 0,
        transactionsByChannel: {},
      };
    });
  
    past10Months.forEach((date) => {
      const monthKey = date.toLocaleString('en-US', { month: 'short' });
      monthTransactions[monthKey] = {
        month: monthKey,
        amount: 0,
        transactionsByChannel: {},
      };
    });
  
    transactions.forEach((transaction) => {
      const transactionDate = new Date(transaction.dateCreated);
      const dayKey = transactionDate.toLocaleString('en-US', { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' });
      const weekKey = `${transactionDate.getMonth() + 1}/${transactionDate.getDate()}/${transactionDate.getFullYear()}`;
      const monthKey = transactionDate.toLocaleString('en-US', { month: 'short' });
  
      if (dayTransactions[dayKey]) {
        dayTransactions[dayKey].amount += transaction.amount;
        if (!dayTransactions[dayKey].transactionsByChannel[transaction.channel]) {
          dayTransactions[dayKey].transactionsByChannel[transaction.channel] = 0;
        }
        dayTransactions[dayKey].transactionsByChannel[transaction.channel] += transaction.amount;
      }
  
      if (weekTransactions[weekKey]) {
        weekTransactions[weekKey].amount += transaction.amount;
        if (!weekTransactions[weekKey].transactionsByChannel[transaction.channel]) {
          weekTransactions[weekKey].transactionsByChannel[transaction.channel] = 0;
        }
        weekTransactions[weekKey].transactionsByChannel[transaction.channel] += transaction.amount;
      }
  
      if (monthTransactions[monthKey]) {
        monthTransactions[monthKey].amount += transaction.amount;
        if (!monthTransactions[monthKey].transactionsByChannel[transaction.channel]) {
          monthTransactions[monthKey].transactionsByChannel[transaction.channel] = 0;
        }
        monthTransactions[monthKey].transactionsByChannel[transaction.channel] += transaction.amount;
      }
    });
  
    const daySummary = Object.values(dayTransactions);
    const weekSummary = Object.values(weekTransactions);
    const monthSummary = Object.values(monthTransactions);
  
    return {
      daySummary,
      weekSummary,
      monthSummary,
    };
  }


  
  