import axios from "axios"
import { authHeader, baseUrl, storeId } from "../../utils"
import { FETCHING_SETTINGS, FETCH_SETTINGS, SETTINGS_ERROR } from "../types"


export const fetchStoreSettings = () => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: FETCHING_SETTINGS,
            payload: true
        })

        const response = await axios.get(`${baseUrl}/settings/${storeId}`, { headers })
        
        dispatch({
            type: FETCH_SETTINGS,
            payload: response.data.data
        })
        
    }
    catch(error){
        console.log(error)
        dispatch({
            type: SETTINGS_ERROR,
            error
        })
    }
}
