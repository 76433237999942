import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types';
import TwoWayChevronIcon from '../icons/TwoWayChevronIcon';

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideAlerter(ref, closeFunction) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            closeFunction()
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);
}
  
const SelectField = ({disabled, selectOptions, inputLabel, displayImage, imageField, titleField, hasError, returnFieldValue, preSelected, preSelectedLabel}) => {
    const [activeValue, setActiveValue] = useState(preSelected)
    const [visibleOptions] = useState(selectOptions)
    const [optionsOpen, setOptionsOpen] = useState(false)

    const [ isFocused, setIsFocused ] = useState(false)

    
    useEffect(() => {
        const preSelect = () => {
            if((!preSelected || preSelected === undefined) && preSelected !== 0) {
                return
            }
    
            selectOptions.forEach((option) => {
                if (option[preSelectedLabel] && option[preSelectedLabel] === preSelected) {
                    setActiveValue(option[titleField])
                }
            })
        }
        preSelect()
    
    }, [preSelected, preSelectedLabel, selectOptions, titleField])
    

    const focusField = () => {
        setIsFocused(true)
        setOptionsOpen(true)
        // document.getElementById(fieldId).focus()
    }

    const unfocusField = () => {
        setIsFocused(false)
        closeOptions()
        // document.getElementById(fieldId).focus()
    }

    const openOptions = () => {
        setOptionsOpen(true)
    }

    const closeOptions = () => {
        setOptionsOpen(false)
    }

    const changeActiveValue = (value, object) => {
        setActiveValue(value)
        returnFieldValue(object)
        closeOptions()
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, closeOptions);

    return (
        // <div className='w-full relative'>
        <div ref={wrapperRef} className='relative w-full'>
            <div 
                className={`w-full cursor-text border rounded py-3 pl-4 pr-3 relative z-0 flex items-center justify-between 
                ${disabled ? 'border-gray-300' : ''} 
                ${isFocused || activeValue !== '' ? 'border-black box-shadow-bottom-solid bg-white' : 'border-transparent bg-gray-100'} 
                ${hasError && 'border-red-600'}`} 
                onClick={()=>{focusField()}} 
                onBlur={()=>{unfocusField()}}
            >
                <label 
                className={`text-sm lg:text-md cursor-text bg-transparent z-30 absolute top-2 left-4 px-3 py-1 transition duration-200  
                ${isFocused || activeValue !== '' ? '-translate-y-7 bg-white' : 'translate-y-0 bg-gray-100'}  
                ${hasError ? 'text-red-600' : 'text-gray-500'}`}>
                    {inputLabel}
                </label>
                
                {/* Text input */}
                <p className='w-full' onClick={()=>{openOptions()}}> 
                    {activeValue} 
                </p>
                {/* <img alt="" src={ChevronDown} className='absolute w-5 top-3 right-3' /> */}
                <button disabled className='z-10 py-1'>
                    <TwoWayChevronIcon className="w-4 h-4 text-black" />
                </button>
            </div>
            {/* Options */}
            {optionsOpen && !disabled &&
                <div className='absolute top-16 border border-black w-full left-0 p-3 bg-white overflow-y-scroll h-inherit pt-8' style={{height: 'inherit', zIndex: 999}}>
                    <button className='absolute top-3 right-3 text-gray-600 hover:text-gray-400 transition duration-200' onClick={()=>{closeOptions()}}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    {visibleOptions.map((option, optionIndex) => (
                        <button key={optionIndex} className='w-full px-2 py-3 flex flex-row gap-x-3 text-sm text-gray-500 transition duration-200 hover:bg-gray-100' onClick={()=>{changeActiveValue(titleField !== '' ? option[titleField] : option, option)}}>
                            {displayImage && 
                                <img alt="" src={option[imageField]} className='w-12' />
                            }
                            {option[titleField]}
                        </button>
                    ))}
                </div>
            }
        </div>
    )
}

SelectField.propTypes = {
    selectOptions: PropTypes.array.isRequired,
    inputLabel: PropTypes.string.isRequired,
    titleField: PropTypes.string.isRequired,
    displayImage: PropTypes.bool.isRequired,
    imageField: PropTypes.string,
    fieldId: PropTypes.string.isRequired,
    hasError: PropTypes.bool,
    returnFieldValue: PropTypes.func.isRequired
};

export default SelectField
