import React from 'react'
const { default: Header } = require("../partials/Header");
const { default: Footer } = require("../partials/Footer");

const Landing = ({children}) => {
  return (
    <>
      <Header />
      <main>
          {children}
      </main>
      <Footer />
    </>
  )
}

export default Landing