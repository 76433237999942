import { CLEAR_SUCCESS, SUCCESS } from "../types";

const initialState = {
    message: null
};
   
export function successReducer(state = initialState, action){
    // const { success } = action;

    if(action.type === SUCCESS){
        return {
            message: action.payload,
        }
    }
    if(action.type === CLEAR_SUCCESS){
        return {
            message: null,
        }
    }

    return state;
}