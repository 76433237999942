import axios from "axios"
import { authHeader, baseUrl, storeId } from "../../utils"
import { FETCHING_MENUS, FETCH_MENUS, MENUS_ERROR } from "../types"

export const fetchStoreMenu = (filterString, page, perPage) => async (dispatch) => {    
    try{
        const headers = authHeader()

        let url = `${baseUrl}/menus/e-commerce/${storeId}?expand=items,items.item,items.parentItem,items.parentItemCategory`
        if(filterString && filterString !== '') {
            url += `${url.includes('?') ? '&' : '?'}${filterString}`
        }

        if(page && page!=='') {
            url += `${url.includes('?') ? '&' : '?'}page=${page}`
        }

        if(perPage && perPage!=='') {
            url += `${url.includes('?') ? '&' : '?'}perPage=${perPage}`
        }

        dispatch({
            type: FETCHING_MENUS,
            payload: true
        })

        const response = await axios.get(url, { headers })
        dispatch({
            type: FETCH_MENUS,
            payload: response.data.data
        })
        
    }
    catch(error){
        // console.log(error)
        dispatch({
            type: MENUS_ERROR,
            error
        })
    }
}
