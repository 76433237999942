import { CATEGORIES_ERROR, CREATED_CATEGORY, CREATING_CATEGORY, DELETED_CATEGORY, DELETING_CATEGORY, FETCHING_CATEGORIES, FETCH_CATEGORIES } from "../types"

const initialState = {
    creatingCategory: false,
    fetchingCategories: false,
    createdCategory: null,
    deletingCategory: false,
    deletedCategory: null,
    categories: null,
    categoriesError: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case CREATING_CATEGORY:
        return {
            ...state,
            creatingCategory: action.payload,
            // fetchingMembers:false
        }
        case CREATED_CATEGORY:
        return {
            ...state,
            creatingCategory: false,
            createdCategory: action.payload
        }
        case DELETING_CATEGORY:
        return {
            ...state,
            deletingCategory: action.payload,
            // fetchingMembers:false
        }
        case DELETED_CATEGORY:
        return {
            ...state,
            deletingCategory: false,
            deletedCategory: action.payload
        }
        case FETCHING_CATEGORIES:
        return {
            ...state,
            fetchingCategories: action.payload,
        }
        case FETCH_CATEGORIES:
        return{
            ...state,
            fetchingCategories:false,
            categories: action.payload,
        }
        case CATEGORIES_ERROR:
        return{
            ...state,
            creatingCategory: false,
            fetchingCategories: false,
            categoriesError: action.payload 
        }
        default: return state
    }

}