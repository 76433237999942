import axios from "axios"
import { authHeader, baseUrl, storeId } from "../../utils"
import { CATEGORIES_ERROR, FETCHING_CATEGORIES, FETCH_CATEGORIES } from "../types"

export const fetchCategories = (filterString) => async (dispatch) => {    
    try{
        const headers = authHeader()

        let url = `${baseUrl}/categories/${storeId}?type=sale`
        if(filterString && filterString !== '') {
            url += `${url.includes('?') ? '&' : '?'}${filterString}`
        }

        dispatch({
            type: FETCHING_CATEGORIES,
            payload: true
        })

        const response = await axios.get(url, { headers })
        
        dispatch({
            type: FETCH_CATEGORIES,
            payload: response.data.data
        })
        
    }
    catch(error){
        // console.log(error)
        dispatch({
            type: CATEGORIES_ERROR,
            error
        })
    }
}
