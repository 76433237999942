import React from 'react'

import StoreItem from '../elements/StoreItem'
import { Link } from 'react-router-dom'

const StorePreview = ({items}) => {
  return (
    <section className="min-h-screen px-8 lg:px-24 xl:px-32 2xl:px-44 mt-24 relative mb-24">
      {/* <div className="w-[55%] h-[90vh] bg-nadabake-purple bg-opacity-10 absolute top-32 left-0 z-0"></div> */}
      <h1 className="text-3xl font-bold leading-[1.2em] mb-6" data-aos="fade-up" data-aos-anchor-placement="top-center">The Store</h1>
      <div className='w-full xl:w-1/2' data-aos="fade-up" data-aos-anchor-placement="top-center">
        <p className="uppercase tracking-[0.2em] mt-3 mb-3 text-nadabake-purple">Skip the line, indulge online</p>
        <p className="text-lg font-medium">From our oven to your doorstep, it&apos;s a journey of pure delight. Don&apos;t just take our word for it, try it for yourself!</p>
      </div>

      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-12'>
        {items?.slice(0, 4).map((item, itemIndex) => (
          <StoreItem key={itemIndex} item={item} />
        ))}
      </div>

      <div className='w-full items-center justify-center flex mt-24' data-aos="fade-down" data-aos-anchor-placement="top-center">
        <Link to="/store" className='w-max flex items-center justify-center px-12 h-20 bg-black hover:bg-white transition duration-200 text-md font-[600] text-white hover:text-black'>
          Start Exploring 
        </Link>
      </div>
    </section>
  )
}

export default StorePreview