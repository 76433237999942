import React, { useState } from 'react'

const TextareaField = ({inputLabel, fieldId, bgClass, requiredField, inputType, hasError, returnFieldValue, preloadValue, disabled}) => {
    const [ isFocused, setIsFocused ] = useState(false)
    const [ fieldValue, setFieldValue ] = useState(preloadValue)

    const focusField = () => {
        setIsFocused(true)
        document.getElementById(fieldId).focus()
    }

    const setValue = (value) => {
        setFieldValue(value)
        returnFieldValue(value)
    }

    return (
        <div 
            className={`w-full cursor-text border p-4 relative z-0 rounded h-inherit min-h-[250px]  
            ${disabled ? 'border-gray-300' : ''} 
            ${isFocused || fieldValue !== '' ? 'border-black bg-white' : 'border-transparent bg-gray-100'} 
            ${hasError && 'border-red-600'}`} 
            onClick={()=>{focusField()}} 
            onBlur={()=>{setIsFocused(false)}}
        >
            {/* {fieldValue} */}
            <label 
                className={`text-sm lg:text-md cursor-text z-10 absolute top-3 left-4 px-3 py-1 transition duration-200  
                ${isFocused || fieldValue !== '' ? '-translate-y-8 text-black bg-white' : 'translate-y-0 text-gray-500 bg-gray-100'}
                ${bgClass && bgClass !== '' ? bgClass : ''}  
                ${hasError ? 'text-red-600' : 'text-gray-500'}`}
            >
             {requiredField && requiredField === true && <span className='text-red-600'>*</span>}   {inputLabel}
            </label>
            <textarea 
                id={fieldId} 
                className={`z-30 border-transparent bg-transparent outline-none w-full font-syne h-inherit min-h-[250px]`} 
                onFocus={()=>{setIsFocused(true)}} 
                onChange={(e)=>{setValue(e.target.value)}}
                value={fieldValue}
                disabled={disabled}
                />
        </div>
    )
}

export default TextareaField