// Categories
export const FETCHING_CATEGORIES = 'FETCHING_CATEGORIES'
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES'
export const FETCH_CATEGORY = 'FETCH_CATEGORY'
export const CREATING_CATEGORY = 'CREATING_CATEGORY'
export const CREATED_CATEGORY = 'CREATED_CATEGORY'
export const DELETING_CATEGORY = 'DELETING_CATEGORY'
export const DELETED_CATEGORY = 'DELETED_CATEGORY'
export const CATEGORIES_ERROR = 'CATEGORIES_ERROR'

// Menus
export const FETCH_MENUS = 'FETCH_MENUS'
export const FETCHING_MENUS = 'FETCHING_MENUS'
export const MENUS_ERROR = 'MENUS_ERROR'

// Carts
export const FETCHING_CART = 'FETCHING_CART'
export const FETCH_CART = 'FETCH_CART'
export const SENDING_TO_CART = 'SENDING_TO_CART'
export const SEND_TO_CART = 'SEND_TO_CART'
export const DEDUCTING_FROM_CART = 'DEDUCTING_FROM_CART'
export const DEDUCT_FROM_CART = 'DEDUCT_FROM_CART'
export const CART_ERROR = 'CART_ERROR'
export const CHECKING_OUT = 'CHECKING_OUT'
export const CHECKOUT = 'CHECKOUT'


// settings
export const UPDATING_SETTINGS = 'UPDATING_SETTINGS';
export const UPDATED_SETTINGS = 'UPDATED_SETTINGS';
export const FETCHING_SETTINGS = 'FETCHING_SETTINGS';
export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const SETTINGS_ERROR = 'SETTINGS_ERROR';

// Errors
export const ERROR = 'ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'

// Success
export const SUCCESS = 'SUCCESS'
export const CLEAR_SUCCESS= 'SUCCESS'