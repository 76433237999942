// reducers/index.js

import { combineReducers } from 'redux';
import categoriesReducer from './categoriesReducer';
import menuReducer from './menuReducer';
import { successReducer } from './successReducer';
import { errorReducer } from './errorReducer';
import cartReducer from './cartReducer';
import settingsReducer from './settingsReducer';

const rootReducer = combineReducers({
  // your reducers go here
  categories: categoriesReducer,
  menu: menuReducer,
  cart: cartReducer,
  settings: settingsReducer,
  success: successReducer,
  error: errorReducer
});

export default rootReducer;
