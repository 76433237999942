import axios from "axios"
import { authHeader, baseUrl, clientId, storeId } from "../../utils"
import { CART_ERROR, CHECKING_OUT, CHECKOUT, FETCHING_CART, FETCH_CART, SENDING_TO_CART, SEND_TO_CART } from "../types"

export const sendToCart = (payload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: SENDING_TO_CART,
            payload: true
        })

        const response = await axios.post(`${baseUrl}/shopping-carts/add/${storeId}`, 
            {
                ...payload, 
                ...{store: storeId}
            }, { headers })
        
        dispatch({
            type: SEND_TO_CART,
            payload: response.data.data
        })
        
    }
    catch(error){
        console.log('send to cart error: ', error)
        dispatch({
            type: CART_ERROR,
            error
        })
    }
}

export const deductFromCart = (payload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: SENDING_TO_CART,
            payload: true
        })

        const response = await axios.post(`${baseUrl}/shopping-carts/deduct/${storeId}`, {...payload, ...{store: storeId}}, { headers })
        
        dispatch({
            type: SEND_TO_CART,
            payload: response.data.data
        })
        
    }
    catch(error){
        console.log('deduction error: ', error)
        dispatch({
            type: CART_ERROR,
            error
        })
    }
}

export const fetchCart = (filterString) => async (dispatch) => {    
    try{
        const headers = authHeader()
        const client = clientId()

        let url = `${baseUrl}/shopping-carts/${storeId}/${client}?expand=items.item,items.parentItem,items.parentItemCategory`
        if(filterString && filterString !== '') {
            url += `${url.includes('?') ? '&' : '?'}${filterString}`
        }

        dispatch({
            type: FETCHING_CART,
            payload: true
        })

        const response = await axios.get(url, { headers })
        
        dispatch({
            type: FETCH_CART,
            payload: response.data.data
        })
        
    }
    catch(error){
        console.log('fetch cart error: ', error)
        if(error.response.data.errorCode !== 'not-found'){
            dispatch({
                type: CART_ERROR,
                error
            })
        } else {
            dispatch({
                type: FETCH_CART,
                payload: null
            })
        }
    }
}

export const checkoutCart = (cartId, payload) => async (dispatch) => {    
    try{
        const headers = authHeader()
        // const client = clientId()

        let url = `${baseUrl}/shopping-carts/${cartId}/checkout`

        dispatch({
            type: CHECKING_OUT,
            payload: true
        })

        const response = await axios.post(url, payload, { headers })
        
        dispatch({
            type: CHECKOUT,
            payload: response.data.data.order
        })
        
    }
    catch(error){
        console.log('fetch cart error: ', error)
        if(error.response.data.errorCode !== 'not-found'){
            dispatch({
                type: CART_ERROR,
                error
            })
        } else {
            dispatch({
                type: FETCH_CART,
                payload: null
            })
        }
    }
}


// export const deleteCategory = (categoryId) => async (dispatch) => {    
//     try{
//         const headers = authHeader()

//         dispatch({
//             type: DELETING_CATEGORY,
//             payload: true
//         })
//         console.log('deleting category in actions...')

//         const response = await axios.delete(`${baseUrl}/categories/${categoryId}`, { headers })
        
//         dispatch({
//             type: DELETED_CATEGORY,
//             payload: response.data.data
//         })
        
//     }
//     catch(error){
//         console.log(error)
//         dispatch({
//             type: CATEGORIES_ERROR,
//             error
//         })
//     }
// }

export const clearCheckedOut = () => async (dispatch) => { 
    dispatch({
        type: CHECKOUT,
        payload: null
    })
}

// export const clearDeletedCategory = () => async (dispatch) => { 
//     dispatch({
//         type: DELETED_CATEGORY,
//         payload: null
//     })
// }