import React, { useEffect } from 'react'
import Landing from '../components/layout/Landing';
import Hero from '../components/partials/Hero';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStoreMenu } from '../store/actions/menuActions';
// import FeaturedCategories from '../components/partials/FeaturedCategories';
import StorePreview from '../components/partials/StorePreview';
import { fetchCategories } from '../store/actions/categoriesActions';
import Loader from '../components/elements/Loader';
import { clearCheckedOut } from '../store/actions/cartActions';
import Pastry from '../assets/img/icons/pancake.png'
import OvenMitts from '../assets/img/icons/oven-mitts.png'
import Star from '../assets/img/icons/hollywood-star.png'

const LandingPage = () => {
    const dispatch = useDispatch();
    const menus = useSelector((state) => state.menu);
    useEffect(() => {
        dispatch(clearCheckedOut())
        dispatch(fetchStoreMenu());
        dispatch(fetchCategories())
        return () => {
        
        };
    }, [dispatch]);
    return (
        <Landing>
        <Hero />

        <section className="w-full px-8 lg:px-24 xl:px-32 2xl:px-44 mt-24">
            <div className="lg:flex items-center gap-x-5 justify-between">
                <div className="w-full p-5 lg:flex gap-x-4" data-aos="fade-up" data-aos-anchor-placement="top-center">
                    <div className="w-[80px] mb-4 lg:mb-0">
                        <div className="w-[75px] h-[75px] bg-opacity-50 flex items-start justify-center">
                            <img src={Pastry} className='w-[90%]' alt='pastry' />
                        </div>
                    </div>
                    <div className="w-full">
                        <h5 className="text-nadabake-purple font-medium text-xl">Pastries to Pamper</h5>
                        <p className="uppercase tracking-[0.2em] mt-2 mb-2 text-gray-400 text-sm">Indulge in sweet temptations.</p>
                        <p className="text-gray-600">Indulge in our exquisite pastries, crafted to delight your senses and pamper your taste buds with every bite.</p>
                    </div>
                </div>
                <div className="w-full p-5 lg:flex gap-x-4" data-aos="fade-up" data-aos-anchor-placement="top-center">
                    <div className="w-[80px] mb-4 lg:mb-0">
                        <div className="w-[75px] h-[75px] bg-opacity-50 flex items-start justify-center">
                            <img src={OvenMitts} className='w-[90%]' alt='oven mitts' />
                        </div>
                    </div>
                    <div className="w-full">
                        <h5 className="text-nadabake-purple font-medium text-xl">Fresh from Oven, Fast to You</h5>
                        <p className="uppercase tracking-[0.2em] mt-2 mb-2 text-gray-400 text-sm">Bites delivered in record time.</p>
                        <p className="text-gray-600">Enjoy our bakery delights, freshly baked and swiftly delivered to your door, ensuring maximum freshness and flavor.</p>
                    </div>
                </div>
                <div className="w-full p-5 lg:flex gap-x-4" data-aos="fade-up" data-aos-anchor-placement="top-center">
                    <div className="w-[80px] mb-4 lg:mb-0">
                        <div className="w-[75px] h-[75px] bg-opacity-50 flex items-start justify-center">
                            <img src={Star} className='w-[90%]' alt='star' />
                        </div>
                    </div>
                    <div className="w-full">
                        <h5 className="text-nadabake-purple font-medium text-xl">Rave Reviews, Real Joy</h5>
                        <p className="uppercase tracking-[0.2em] mt-2 mb-2 text-gray-400 text-sm">See what our happy customers say</p>
                        <p className="text-gray-600">Our customers keep coming back, with rave reviews celebrating our delicious and delightful bakery treats.</p>
                    </div>
                </div>
            </div>
        </section>

        {/* <FeaturedCategories /> */}

        {menus?.fetchingMenus 
        ? <Loader />
        : <StorePreview items={menus?.menu?.items} />
        }
        </Landing>
    )
}

export default LandingPage
