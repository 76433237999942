import React from 'react'
import StarSolid from './icons/StarSolid'

const Rating = ({rating}) => {
  return (
    <div className='flex items-center'>
        <p className='font-medium number mr-2'>5.0</p>
        <StarSolid className={`text-yellow-500 w-5 h-5`} />
        <StarSolid className={`text-yellow-500 w-5 h-5`} />
        <StarSolid className={`text-yellow-500 w-5 h-5`} />
        <StarSolid className={`text-yellow-500 w-5 h-5`} />
        <StarSolid className={`text-yellow-500 w-5 h-5`} />
    </div>
  )
}

export default Rating