import { FETCHING_MENUS, FETCH_MENUS} from "../types"

const initialState = {
    creatingMenu: false,
    createdMenu: null,
    deletingMenu: false,
    deletedMenu: null,
    updatingMenu: false,
    updatedMenu: null,
    fetchingMenus: false,
    menu: null,
    menusError: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case FETCHING_MENUS:
        return {
            ...state,
            fetchingMenus: action.payload,
        }
        case FETCH_MENUS:
        return{
            ...state,
            fetchingMenus:false,
            menu: action.payload,
        }
        default: return state
    }

}