import React, { useCallback, useEffect, useState } from 'react'
import EyeIcon from '../icons/EyeIcon'
import EyeOffIcon from '../icons/EyeOffIcon'

const TextField = ({
    requiredField,
    inputLabel, 
    fieldId, 
    inputType, 
    hasError, 
    returnFieldValue, 
    preloadValue, 
    disabled, 
    includeButton, 
    buttonLabel, 
    buttonAction,
    bgClass,
    autoFocus,
    maxLength,
    hidePasswordToggler
}) => {
    const [ isFocused, setIsFocused ] = useState(false)
    const [ fieldValue, setFieldValue ] = useState(preloadValue)
    const [ hiddenInput, setHiddenInput ] = useState(true)
    // const id = generateCode(12)

    // const [fieldId, setFieldId] = useState(id)

    const toggleHiddenInput = (e) => {
        e.preventDefault()
        setHiddenInput(!hiddenInput)
    }

    const focusField = useCallback(() => {
        if(disabled) {return}
        setIsFocused(true)
        document.getElementById(fieldId).focus()
    }, [disabled, fieldId])

    useEffect(() => {
    //   setFieldValue(preloadValue)
        if (autoFocus && autoFocus === true) {
            focusField()
        }

    }, [autoFocus, focusField])

    const setValue = (value) => {
        setFieldValue(value)
        returnFieldValue(value)
    }

    return (
        <div 
        // ${isFocused || fieldValue !== '' ? 'border-black box-shadow-bottom-solid bg-white' : 'border-black bg-gray-100'} 
            className={`w-full cursor-text border p-3 rounded relative z-0 
            ${disabled ? 'border-gray-300' : ''} 
            ${isFocused || fieldValue !== '' ? 'border-black bg-white' : 'border-transparent bg-gray-100'} 
            ${hasError && 'border-red-600'}`} 
            onClick={()=>{focusField()}} 
            onBlur={()=>{setIsFocused(false)}}
        >
            {/* {fieldValue} */}
            <label 
                className={`text-sm lg:text-md cursor-text z-10 absolute top-2 left-4 px-3 py-1 transition duration-200  
                ${isFocused || fieldValue !== '' ? '-translate-y-7 text-black bg-white' : 'translate-y-0 text-gray-500 bg-gray-100'}
                ${bgClass && bgClass !== '' ? bgClass : ''}  
                ${hasError ? 'text-red-600' : 'text-gray-500'}`}
            >
             {requiredField && requiredField === true && <span className='text-red-600'>*</span>}   {inputLabel}
            </label>

            {inputType === "date" ? <input 
                id={fieldId} 
                type="date" 
                className={`z-30 border-transparent bg-transparent outline-none w-full`} 
                onFocus={()=>{setIsFocused(true)}} 
                onChange={(e)=>{setValue(e.target.value)}}
                value={fieldValue}
                disabled={disabled}
            /> :
            inputType === "number" ? <input 
                id={fieldId} 
                type="number" 
                className={`z-30 border-transparent bg-transparent outline-none w-full`} 
                onFocus={()=>{setIsFocused(true)}} 
                onChange={(e)=>{setValue(e.target.value)}}
                value={fieldValue}
                disabled={disabled}
                maxLength={maxLength}
                pattern="\d*"
            /> :
            inputType === "number" ? <input 
                id={fieldId} 
                type="number" 
                className={`z-30 border-transparent outline-none w-full`} 
                onFocus={()=>{setIsFocused(true)}} 
                onChange={(e)=>{setValue(e.target.value)}}
                value={fieldValue}
                disabled={disabled}
                maxLength={maxLength}
                pattern="\d*"
            /> :
            <input 
                id={fieldId} 
                type={hiddenInput && inputType === 'password' ? inputType : "text"} 
                maxLength={maxLength}
                className={`z-30 border-transparent bg-transparent outline-none w-full`} 
                onFocus={()=>{setIsFocused(true)}} 
                onChange={(e)=>{setValue(e.target.value)}}
                value={fieldValue}
                disabled={disabled}
            />}

            {inputType === 'password' && !hidePasswordToggler &&

            <span className={`absolute z-40 top-4 cursor-pointer ${includeButton && includeButton === true ? 'right-32' : 'right-4'}`} onClick={(e)=>{toggleHiddenInput(e)}}>
                {hiddenInput ?
                <EyeIcon />
                :
                <EyeOffIcon />}
            </span>
            }
            {includeButton && includeButton === true && 
            <button className={`w-24 text-center py-2 text-sm rounded bg-gray-200 text-black absolute z-40 right-4 top-3 hover:bg-black hover:text-white transition duration-200`} onClick={()=>{buttonAction()}}>
               {buttonLabel}
            </button>
            }

        </div>
    )
}

export default TextField